import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Flex } from 'components/Flex';
import { Rhythm } from 'components/Rhythm';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Notification } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="components/Form/Notification" title="Notification" mdxType="PageTitle" />
    <h2 {...{
      "id": "basic-notification"
    }}>{`Basic notification`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Notification notification=\"Basic notification\">\n    Content that the basic notification refers to.\n  </Notification>\n  <br />\n  <Notification divided notification=\"Divided notification\">\n    Content that the divided notification refers to.\n  </Notification>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Notification,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Notification notification="Basic notification" mdxType="Notification">Content that the basic notification refers to.</Notification>
    <br />
    <Notification divided notification="Divided notification" mdxType="Notification">
      Content that the divided notification refers to.
    </Notification>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "status-notification"
    }}>{`Status notification`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Flex direction=\"column\">\n    <Rhythm mb={1}>\n      <Notification color=\"success\" notification=\"Success notification\" />\n      <Notification color=\"warning\" notification=\"Warning notification\" />\n      <Notification color=\"danger\" notification=\"Danger notification\" />\n      <Notification\n        divided\n        color=\"primary\"\n        notification=\"Primary notification\"\n      />\n      <Notification color=\"neutral\" notification=\"Neutral notification\" />\n    </Rhythm>\n  </Flex>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Flex,
      Rhythm,
      PageTitle,
      ThemeWrapper,
      Notification,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Flex direction="column" mdxType="Flex">
      <Rhythm mb={1} mdxType="Rhythm">
        <Notification color="success" notification="Success notification" mdxType="Notification" />
        <Notification color="warning" notification="Warning notification" mdxType="Notification" />
        <Notification color="danger" notification="Danger notification" mdxType="Notification" />
        <Notification divided color="primary" notification="Primary notification" mdxType="Notification" />
        <Notification color="neutral" notification="Neutral notification" mdxType="Notification" />
      </Rhythm>
    </Flex>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Notification} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      